<template>
  <modal-dialog
      :show="showModal"
      @close="close"
      target-class="modal__card--mini"
  >
    <product-added-to-list @close="close()" />
  </modal-dialog>
</template>

<script>
import CanBeCanceledByEscKey from "../../../Mixins/CanBeCanceledByEscKey"
import ModalDialog from "../../../components/ui/ModalDialog";
import ProductAddedToList from "../../../components/ProductAddedToList";

export default {
  name: 'ProductAddedToListModal',
  mixins: [CanBeCanceledByEscKey],
  components: {
    ModalDialog,
    ProductAddedToList,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showModal: false
    }
  },
  methods: {
    close() {
      this.showModal = false
      this.$emit('cancel')
    },
  },
  mounted() {
    this.showModal = this.show
  },
  watch: {
    show: function (val) {
      this.showModal = val
    }
  }
}
</script>
